import { useStitchAuth } from '../../StitchAuth';
import { useState, useEffect, useMemo } from 'react';

/**
 * Hook to check monthly device ordering limits
 * @param {boolean} [detailedInfo=false] - If true, returns detailed limit information
 * @returns {boolean|Object} - Boolean if limit is triggered, or object with detailed info
 */
export function useDeviceLimitTrigger(detailedInfo = false) {
  const { db: { registry, tenovidevices, groups }, userCustomData } = useStitchAuth();
  const [deviceLimitInfo, setDeviceLimitInfo] = useState({
    isLimitReached: false,
    totalOrdered: 0,
    monthlyLimit: 2, // Default fallback
    remaining: 2
  });

  // Calculate date range once to avoid recreating in multiple places
  const dateRange = useMemo(() => {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const firstDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    
    return {
      start: firstDayOfMonth.toISOString(),
      end: firstDayOfNextMonth.toISOString()
    };
  }, []);

  useEffect(() => {
    if (!userCustomData?.group_id) return;
    
    async function checkDeviceLimit() {
      try {
        // Fetch group limit
        let monthlyLimit = 25; // Default fallback
        try {
          const groupDoc = await groups.findOne({ group_id: userCustomData.group_id });
          if (groupDoc) {
            // Check for new field name first, then fall back to old field name
            monthlyLimit = groupDoc.monthlyDeviceOrderLimit || groupDoc.monthly_device_orders || monthlyLimit;
          }
        } catch (error) {
          console.error("Error fetching group device limit:", error);
          // Continue with default value
        }

        // Count devices ordered this month (run queries in parallel)
        const [registryCount, tenoviCount] = await Promise.all([
          registry.count({
            owner_id: userCustomData.group_id,
            $or: [
              { isBulkOrder: false },
              { isBulkOrder: { $exists: false } }
            ],
            startDate: {
              $gte: dateRange.start,
              $lt: dateRange.end
            }
          }),
          tenovidevices.count({
            owner_id: userCustomData.group_id,
            $or: [
              { isBulkOrder: false },
              { isBulkOrder: { $exists: false } }
            ],
            'tenoviInfo.device.created': {
              $gte: dateRange.start,
              $lt: dateRange.end
            }
          })
        ]);
    
        const totalDevicesOrdered = registryCount + tenoviCount;
        const isLimitReached = totalDevicesOrdered >= monthlyLimit;
        const remaining = Math.max(0, monthlyLimit - totalDevicesOrdered);
        
        setDeviceLimitInfo({
          isLimitReached,
          totalOrdered: totalDevicesOrdered,
          monthlyLimit: monthlyLimit,
          remaining: remaining
        });
      } catch (error) {
        console.error("Error checking device limits:", error);
      }
    }
    
    checkDeviceLimit();
  }, [registry, tenovidevices, groups, userCustomData, dateRange]);

  // Return detailed info or just the boolean based on the parameter
  return detailedInfo ? deviceLimitInfo : deviceLimitInfo.isLimitReached;
}