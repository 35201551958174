import * as Realm from 'realm-web';
import { app } from './app.js';
//import { users, groups } from "./mongodb.js";
import defaultConfigs from '../components/defaultConfigs.json';

const billingConfigDefault = defaultConfigs.billingConfig;

export function hasLoggedInUser() {
  // Check if there is currently a logged in user
  return app.currentUser && app.currentUser._accessToken !== null;
}

export function getCurrentUser() {
  // Return the user object of the currently logged in user
  //console.log(app.currentUser);
  return app.currentUser;
}

export const getCustomData = async (clientCode = 'orma') => {
  if (app.currentUser) {
    const mongodb = app.currentUser.mongoClient('mongodb-atlas-test');
    const users = mongodb.db('test').collection('users');
    const groups = mongodb.db('test').collection('groups');

    let customData = await users.findOne({ id: app.currentUser.id });

    if (!customData) {
      /*
      customData = {
        name: '',
        namePublic: '',
        timeZone: 'America/Los_Angeles',
        role: 'admin',
        emailNotifications: {
          periodicAP: true,
          periodicMP: false,
          realtimeAP: false,
          realtimeMP: false,
        },
        //group_id: app.currentUser.profile.email, // handled at the backend
        //id: app.currentUser.id,
        email: app.currentUser.profile.email,
      };
      await users.insertOne(customData);
      */
      await app.currentUser.functions.insertUser();
      customData = await users.findOne({ id: app.currentUser.id });
    }

    let group_ids = customData.workspaces ? customData.workspaces.map(workspace => workspace.group_id): [];

    let query = {
      group_id: { $in: group_ids },
    }

    let groupsList = await app.currentUser.functions.fetchGroups(query);
    let groupData = groupsList.find(group => group.group_id === customData.group_id);
    
    groupsList.sort((a, b) => {
      let indexA = group_ids.indexOf(a.group_id);
      let indexB = group_ids.indexOf(b.group_id);
      return indexA - indexB;
    });
    
    customData.workspaces = groupsList;

    if (!groupData) {
      const smsTemplates =
        await app.currentUser.functions.fetchSMSTemplates();
      groupData = {
        group_id: customData.group_id,
        customer_agreement_consent: false, // deprecated
        termsAndConditions: 'no', // deprecated
        hasBasicInformation: false,
        signedContract: true, // as our user sign-ups increase, we do not want to show "trial banner" - changed by YPark on July 11, 2023
        text_usage_consent: false,
        auto_alert_text: false,
        auto_outreach_text: false,
        auto_reminder_text: false,
        clinical_rules: {
          highBPWarning: [],
          lowBPWarning: [],
          highHRWarning: [],
          lowHRWarning: [],
          weightLossWarning: [],
          weightGainWarning: [],
          lowBloodSugarWarning: [],
          highBloodSugarWarning: [],
          ecgWarning: [],
        },
        smsTemplates: {
          ...smsTemplates,
        },
        billingConfig: { ...billingConfigDefault },
        clientName: clientCode, // clientName
        services: ['RPM'],
      };
      // NOTE: this should move to the backend soon...
      await groups.insertOne(groupData);
    } else {
      // for backward compatibility
      groupData = {
        billingConfig: { ...billingConfigDefault },
        ...groupData,
      };
    }

    // NOTE: billing config and view config added later
    // For backward compatibility, we need the following block of codes
    /*
    Object.keys(billingConfigDefault).forEach(k => {
      if(groupData.billingConfig[k]===undefined){
        groupData.billingConfig[k] = billingConfigDefault[k]
      }
    });
    Object.keys(viewConfigDefault).forEach(k => {
      if(groupData.viewConfig[k]===undefined){
        groupData.viewConfig[k] = viewConfigDefault[k]
      }
    });
    */

    const data = { ...groupData, ...customData };

    return data;
  } else {
    return null;
  }
};

export function logoutCurrentUser() {
  // Logout the currently logged in user
  return app.currentUser.logOut();
}

export async function signupUser(email, password) {
  return await app.emailPasswordAuth.registerUser(email, password);
}

export async function confirmEmail(token, tokenId) {
  return await app.emailPasswordAuth.confirmUser(token, tokenId);
}

export async function loginUser(email, password) {
  const credentials = Realm.Credentials.emailPassword(
    email,
    password,
  );
  //const credential = new UserPasswordCredential(email, password);
  try {
    const user = await app.logIn(credentials);
    //assert(user.id===app.currentUser.id);
    return user;
  } catch (err) {
    console.error('Failed to log in', err);
  }
}

export async function sendResetPasswordEmail(email) {
  return await app.emailPasswordAuth.sendResetPasswordEmail(email);
}

export async function resetPassword(token, tokenId, newPassword) {
  return await app.emailPasswordAuth.resetPassword(
    token,
    tokenId,
    newPassword,
  );
}
