import { useLocation } from 'react-router-dom';

export function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '.';
  }
}

export function toTitleCase(x) {
  return x.replace(/\w*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function removeSpecialChars(x) {
  return x.replace(/[^\w\s]/gi, '');
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getMMDDYYYY(date) {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export function handlePhoneNumberFormat(phoneNumber) {
  const numOnly = phoneNumber.replace(/(\D+)/g, '');
  let phoneFormat = numOnly.slice(0, 3);
  if (numOnly.length >= 3) {
    phoneFormat = phoneFormat + '-' + numOnly.slice(3, 6);
  }
  if (numOnly.length >= 6) {
    phoneFormat = phoneFormat + '-' + numOnly.slice(6, 10);
  }
  return phoneFormat;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function getTimeOffset(inputDate) {
  let offset = new Date().getTimezoneOffset() / 60;
  if (inputDate) {
    offset = new Date(inputDate).getTimezoneOffset() / 60;
  }
  return 3600000 * offset;
}

export function getEndOfMonth(ymRef) {
  const monthMap = {
    '01': 31,
    '02': 28,
    '03': 31,
    '04': 30,
    '05': 31,
    '06': 30,
    '07': 31,
    '08': 31,
    '09': 30,
    10: 31,
    11: 30,
    12: 31,
  };
  const ym = ymRef ? ymRef : new Date().toISOString().slice(0, 7);
  const yearMonth = ym.split('-');
  const year = parseInt(yearMonth[0]);
  const month = yearMonth[1];
  let endDate = monthMap[month];
  if (year % 4 === 0 && month === '02') {
    endDate++;
  }
  return ym + '-' + endDate;
}

export function getYMRange() {
  const startYear = 2019;
  const startMonth = 1;
  const endYear = new Date().getYear() + 1900;
  const endMonth = new Date().getMonth() + 1;
  const ymList = [];
  for (let year = startYear; year <= endYear; year++) {
    const mStart = year === startYear ? startMonth : 1;
    const mEnd = year === endYear ? endMonth : 12;
    for (let month = mStart; month <= mEnd; month++) {
      const mStr = month < 10 ? '0' + month : '' + month;
      const key = year + '-' + mStr;
      ymList.push({ key: key, text: key, value: key });
    }
  }
  return ymList.reverse();
}

export function getDateRange(ymRef) {
  const eom = getEndOfMonth(ymRef);
  let numDays = parseInt(eom.substring(8, 10));
  let maxDay =
    Math.floor(new Date(eom).getTime() / 86400000) * 86400000;

  const today =
    Math.floor(
      (new Date().getTime() - getTimeOffset(new Date())) / 86400000,
    ) * 86400000;

  // comparison to see if most recent day should be last day of month or today
  if (maxDay > today) {
    maxDay = today;
    numDays = parseInt(new Date().toISOString().substring(8, 10));
  }
  let array = [
    {
      key: '0',
      value: maxDay + getTimeOffset(maxDay),
      text: getMMDDYYYY(new Date(maxDay + getTimeOffset(maxDay))),
    },
  ];
  for (let i = 1; i < numDays; i++) {
    const prevDay = new Date(array[i - 1].value);
    // the day that comes before the last day placed in the array
    let thisDay = prevDay.setDate(prevDay.getDate() - 1);
    array.push({
      key: i,
      value: thisDay,
      text: getMMDDYYYY(new Date(thisDay)),
    });
  }
  return array;
}

export function getTimeRange() {
  let array = [];
  //const offset = getTimeOffset();

  for (let i = 0; i < 24; i++) {
    let text = '';
    if (i % 12 < 10) {
      text = '0' + (i % 12);
    } else {
      text = '' + (i % 12);
    }
    if (i < 12) {
      text += ' AM';
    } else {
      text += ' PM';
    }
    array.push({
      key: i,
      value: 3600000 * i,
      text: text,
    });
  }

  return array;
}

export function toDomesticPhoneFormat(x) {
  if (x && typeof x === 'string') {
    return (
      x.substring(2, 5) +
      '-' +
      x.substring(5, 8) +
      '-' +
      x.substring(8, 12)
    );
  } else {
    return 'XXX-XXX-XXXX';
  }
}

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export const formatName = (name) => {
  // get name suffix if there is one.
  let suffix = '';
  const nameTokens = name.replace(/\s\s+/g, ' ').trim().split(' ');
  if (
    ['sr.', 'sr', 'jr.', 'jr'].includes(
      nameTokens[nameTokens.length - 1].toLowerCase(),
    )
  ) {
    suffix = nameTokens[nameTokens.length - 1];
    nameTokens.pop();
  }

  const firstName = nameTokens[0];
  const lastName = nameTokens[nameTokens.length - 1];
  let formattedName = `${lastName}, ${firstName}`;
  // append middle initials if there are any
  if (nameTokens.length > 2) {
    const middleInitial = nameTokens
      .slice(1, nameTokens.length - 1)
      .map((x) => x[0].toUpperCase())
      .join('. ');
    formattedName = `${lastName}, ${firstName}, ${middleInitial}.`;
  }

  // append suffix if there was one
  if (suffix) {
    return `${formattedName} ${suffix}`;
  } else {
    return formattedName;
  }
};

export const formatDate = (valueOld, valueNew) => {
  // See: MemberDetails/ManualEntry.js

  let valueFormatted = valueOld;
  const mm = parseInt(valueNew.substring(0, 2));
  const dd = parseInt(valueNew.substring(3, 5));
  const yyyy = parseInt(valueNew.substring(6, 10));
  if (valueOld.length > valueNew.length) {
    // delete operation
    valueFormatted = valueNew;
    if (valueOld.length === 6 || valueOld.length === 3) {
      valueFormatted = valueNew.substring(0, valueNew.length - 1);
    }
  } else if (!isNaN(valueNew[valueNew.length - 1])) {
    if (mm <= 12) {
      if (mm > 1 && mm < 10) {
        valueFormatted = '0' + mm;
      } else {
        valueFormatted = valueNew.substring(0, 2);
      }
    }
    if (valueFormatted.length === 2) {
      valueFormatted += '/';
    }
    if (valueNew.length >= 3) {
      if (dd <= 31) {
        if (dd > 3 && dd < 10) {
          valueFormatted += '0' + dd;
        } else {
          valueFormatted += valueNew.substring(3, 5);
        }
      }
    }
    if (valueFormatted.length === 5) {
      valueFormatted += '/';
    }
    if (valueNew.length >= 6) {
      if (yyyy < 2100) {
        valueFormatted += '' + yyyy;
      }
    }
  }
  return valueFormatted;
};

export function sortMeasurementDates(measurementArray, ymRef) {
  let sortedArray = (measurementArray || [])
    .filter((measure) => measure.time.substring(0, 7) === ymRef)
    .sort(function (a, b) {
      return new Date(b.time) - new Date(a.time);
    });

  return sortedArray;
}

export const camelCase = (str) => {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

export const deviceOptions = [
  { key: 'bt004', text: 'Body Trace Weight Scale (BT004)', value: 'BT004', type: 'bodytrace' },
  { key: 'bt105', text: 'Body Trace Blood Pressure (BT105)', value: 'BT105', type: 'bodytrace' },
  { key: 'TENOVI_SCALE', text: 'Tenovi Weight Scale', value: 'TENOVI_SCALE', type: 'tenovi' },
  { key: 'TENOVI_BPM', text: 'Tenovi Blood Pressure Cuff', value: 'TENOVI_BPM', type: 'tenovi' },
  { key: 'TENOVI_BPM_XL', text: 'Tenovi Blood Pressure Cuff XL', value: 'TENOVI_BPM_XL', type: 'tenovi' },
  { key: 'TENOVI_GLUCOMETER', text: 'Tenovi Glucometer', value: 'TENOVI_GLUCOMETER', type: 'tenovi' },
];

/**
 * Returns the display name for a group based on its ID
 * @param {string} groupId - The ID of the group
 * @param {Array} groups - Array of group objects
 * @returns {string} - The display name of the group or the group ID if not found
 */
export const getGroupNameById = (groupId, groups) => {
  const group = groups.find(g => g.group_id === groupId);
  return group ? (group.workspaceName || group.agreement?.contactName || "No Name") : groupId;
};

/**
 * Returns the display name for a device based on its code
 * @param {string} deviceCode - The code of the device
 * @returns {string} - The display name of the device or the device code if not found
 */
export const getDeviceDisplayName = (deviceCode) => {
  const device = deviceOptions.find(d => d.value === deviceCode);
  return device ? device.text : deviceCode;
};