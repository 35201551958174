import React, { useState, useEffect, useCallback } from 'react';
import { Form, Message } from 'semantic-ui-react';
import { useDeviceLimitTrigger } from '../../../MemberDetails/Devices/useDeviceLimitTrigger';
import BloodGlucoseDevice from './BloodGlucoseDevice';
import BloodPressureDevice from './BloodPressureDevice';
import WeightScaleDevice from './WeightScaleDevice';
import TenoviWeightScale from './TenoviWeightScale';
import TenoviBloodPressure from './TenoviBloodPressure';
import TenoviGlucometer from './TenoviGlucometer';
import TenoviBloodPressureXL from './TenoviBloodPressureXL';

const DevicesSection = ({
  formError,
  setFormError,
  bt004,
  bt105,
  smig,
  bt004Imei,
  bt105Imei,
  smigImei,
  setBt004,
  setBt105,
  setSmig,
  setBt004Imei,
  setBt105Imei,
  setSmigImei,
  setSendEnrollmentText,
  tenoviDeviceTypes,
  setTenoviDeviceTypes,
  tenoviGatewayId,
  setTenoviGatewayId,
  setIsRequestLimitExceeded,
  tenoviSelection,
  setTenoviSelection
}) => {
  const [bodyTraceSelection, setBodyTraceSelection] = useState(false);
  
  // Get detailed device limit information
  const { 
    isLimitReached, 
    totalOrdered, 
    monthlyLimit, 
    remaining 
  } = useDeviceLimitTrigger(true);
  
  const checkAlphaNumbericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])/;

  // Calculate the number of devices being requested
  const countRequestedDevices = useCallback(() => {
    let count = 0;
    if (bodyTraceSelection) {
      if (bt004 === 'request') count++;
      if (bt105 === 'request') count++;
      if (smig === 'request') count++;
    }
    if (tenoviSelection) {
      Object.values(tenoviDeviceTypes).forEach(device => {
        if (device.state === 'request') count++;
      });
    }
    return count;
  }, [bodyTraceSelection, tenoviSelection, bt004, bt105, smig, tenoviDeviceTypes]);
  
  // Check limits and update parent state
  useEffect(() => {
    const requestedDevices = countRequestedDevices();
    // Check per-form limit (still 3)
    const exceedsFormLimit = requestedDevices > 3;
    // Check if these new devices would exceed the monthly limit
    const wouldExceedMonthly = totalOrdered + requestedDevices > monthlyLimit;
    
    // Set the overall limit exceeded flag
    setIsRequestLimitExceeded(exceedsFormLimit || isLimitReached || wouldExceedMonthly);
  }, [
    bt004, bt105, smig, tenoviDeviceTypes, 
    bodyTraceSelection, tenoviSelection, 
    setIsRequestLimitExceeded, countRequestedDevices, 
    isLimitReached, totalOrdered, monthlyLimit
  ]);

  // Check if any devices are being connected (not just requested)
  const hasConnectingDevices = Object.values(tenoviDeviceTypes).some(
    device => device.state === 'connect'
  );

  // Render the appropriate limit message
  const renderLimitMessage = () => {
    if (isLimitReached) {
      return (
        <Message visible warning>
          <Message.Header>Monthly Device Order Limit Reached</Message.Header>
          <p>Your organization has reached its monthly limit of {monthlyLimit} device orders. Please contact your administrator if you need to order additional devices.</p>
        </Message>
      );
    }
    
    const requestedDevices = countRequestedDevices();
    const wouldExceedMonthly = totalOrdered + requestedDevices > monthlyLimit;
    
    if (wouldExceedMonthly) {
      return (
        <Message visible warning>
          <Message.Header>This Would Exceed Your Monthly Device Limit</Message.Header>
          <p>Your organization has ordered {totalOrdered} out of {monthlyLimit} allowed devices this month. 
          The devices you're requesting would exceed your monthly limit. Please reduce the number of devices or contact your administrator.</p>
        </Message>
      );
    }
    
    return (
      <Message visible info>
        <p>
          You can request up to 3 devices for enrollment. 
          <span> Your organization has {remaining} device order{remaining !== 1 ? 's' : ''} remaining this month.</span>
        </p>
      </Message>
    );
  };

  return (
    <>
      {renderLimitMessage()}
      
      <b>Request or Connect Devices</b>

      <Form.Group widths="equal" style={{ marginTop: 5 }}>
        <Form.Checkbox
          label="Tenovi Devices"
          checked={tenoviSelection}
          value="tenovi"
          onChange={() => setTenoviSelection(!tenoviSelection)}
          disabled={isLimitReached}
        />
        <Form.Checkbox
          label="Body Trace and Smart Meter"
          checked={bodyTraceSelection}
          value="bodyTrace"
          onChange={() => setBodyTraceSelection(!bodyTraceSelection)}
          disabled={isLimitReached}
        />
      </Form.Group>
      {tenoviSelection && (
        <>
          <b>Tenovi Device Connections:</b> <br />{' '}
          {hasConnectingDevices && (
            <Form.Group>
              <Form.Input
                error={formError}
                fluid
                name="tenoviGatewayId"
                maxLength={12}
                label={'Gateway ID: (12 characters, alphanumeric. No dashes)'}
                placeholder={'A1B234CD56EF'}
                value={tenoviGatewayId}
                onBlur={(e) => {
                  setFormError(
                    !checkAlphaNumbericRegex.test(tenoviGatewayId) || 
                    tenoviGatewayId.length !== 12
                  );
                }}
                onChange={(e) => {
                  const newText = e.target.value;
                  const onlyNumbersAndLetters = newText.replace(/[^A-Za-z0-9]/g, '');
                  const allCap = onlyNumbersAndLetters.toUpperCase();
                  setTenoviGatewayId(allCap);
                }}
                style={{ width: '150px' }}
              />
            </Form.Group>
          )}
          <TenoviWeightScale
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviBloodPressure
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviBloodPressureXL
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
          <TenoviGlucometer
            tenoviDeviceTypes={tenoviDeviceTypes}
            setTenoviDeviceTypes={setTenoviDeviceTypes}
          />
        </>
      )}
      {bodyTraceSelection && (
        <>
          <b>Body Trace and Smart Meter Device Connections:</b> <br />{' '}
          <WeightScaleDevice
            bt004={bt004}
            bt004Imei={bt004Imei}
            setBt004={setBt004}
            setBt004Imei={setBt004Imei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
          <BloodPressureDevice
            bt105={bt105}
            bt105Imei={bt105Imei}
            setBt105={setBt105}
            setBt105Imei={setBt105Imei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
          <BloodGlucoseDevice
            smig={smig}
            smigImei={smigImei}
            setSmig={setSmig}
            setSmigImei={setSmigImei}
            setSendEnrollmentText={setSendEnrollmentText}
          />
        </>
      )}
    </>
  );
};

export default DevicesSection;
