import React from 'react';
import { Form, Message } from 'semantic-ui-react';
import CcmConsentSection from './CcmServicesSection/CcmConsentSection';
import RPMConsentSection from './RPMConsentSection';

const FormActions = ({
  confirm,
  setConfirm,
  writeMessage,
  memberName,
  sendEnrollmentText,
  setSendEnrollmentText,
  memberExists,
  handleSubmit,
  ccmButtonClicked,
  ccmServiceFields,
  setCcmServiceFields,
  isRequestLimitExceeded,
  isSubmitting,
}) => {
  let memberConsented = ccmButtonClicked
    ? !confirm || memberExists || !ccmServiceFields.ccmPatientConsent
    : !confirm || memberExists;

  return (
    <>
      <RPMConsentSection confirm={confirm} setConfirm={setConfirm} />
      {ccmButtonClicked && (
        <CcmConsentSection
          ccmServiceFields={ccmServiceFields}
          setCcmServiceFields={setCcmServiceFields}
        />
      )}
      <Form.Checkbox
        label={
          <label>
            Send an enrollment text message: <br />
            <i>"{writeMessage(memberName)}"</i>
          </label>
        }
        checked={sendEnrollmentText}
        onChange={() => setSendEnrollmentText(!sendEnrollmentText)}
      />
      <Message
        error
        header="Missing Information"
        content="Please check that all fields are complete."
      />
      <Form.Button
        primary
        loading={isSubmitting}
        disabled={memberConsented || isRequestLimitExceeded || isSubmitting}
        onClick={handleSubmit}
      >
        Enroll the member and send RPM devices
      </Form.Button>
    </>
  );
};

export default FormActions;
